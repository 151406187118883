<template>
  <div>
    <div class="card d-flex">
      <div class="card-body">
        <div class="calendar justify-content-between mt-0">
          <p
            v-if="boardType == 'project'"
            class="task-timesheet"
          >
            {{ projectname }} Project Archive List
          </p>
          <p
            v-else
            class="task-timesheet"
          >
            Archive List
          </p>
          <button
            class="back-button btn btn-secondary mx-2"
            @click="Back()"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              width="18"
              height="18"
            />
            <span>Back</span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="accordion2"
      role="tablist"
    >
      <div class="emp-list tableDesigns archive-list-table">
        <TableLoader
          v-if="loading"
          :fields="fields"
        />
        <b-table
          v-else
          small
          :fields="fields"
          :items="archiveTasks"
          responsive
          class="scrollable-element emp-list-data emp-list-report table-responsive project-task-table"
        >
          <!-- {{data.item}} -->
          <template #cell(title)="data">
            <!-- {{items }} -->
            <div class="d-flex align-items-center">
              <span
                v-b-tooltip.hover
                class="improvement-fill-span"
                title="Task"
              ><img
                src="../../../assets/images/Image/improvement.svg"
              ></span>
              <div>
                <p class="project-title-dashboard">
                  {{ data.item.title }}
                </p>
                <p
                  v-if="
                    data.item.project_plan_milestone &&
                      data.item.project_plan_sprint
                  "
                  class="milestone-sprint-dashboard"
                >
                  <span> {{ data.item.project_plan_milestone.title }}</span>
                  ->
                  <span>{{ data.item.project_plan_sprint.title }}</span>
                </p>
              </div>
            </div>
          </template>

          <template
            #cell(ticketno)="data"
            class="text-center"
          >
            <div>
              <span class="ticket"> {{ data.item.number }}</span><!---->
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              <span
                v-if="data.item.status"
                class="status-progress"
                style="white-space: nowrap"
              >{{
                data.item.status.name
              }}</span>
            </div>

          </template>

          <template #cell(timeest)="data">
            <div>
              <span class="esttime">{{
                data.item.estimated_hours | hoursInTimeFormat
              }}</span>
            </div>
            <!-- if time is not define -->

          </template>
          <template #cell(timespent)="data">
            <span
              v-if="data.item.project_task_spent_minutes > 0"
              class="esttime"
            />
            <!--if not  have project task & total_time 0 then show icon-->
            <div
              v-if="!data.item.project_task_spent_minutes && data.item.project_task_spent_minutes < 1"
              class="clock-icon-span ml-0"
            >
              <feather-icon
                v-b-tooltip.hover
                icon="ClockIcon"
                size="18"
                title="Not Time Spend"
              />
            </div>
            <span
              v-else
              class="esttime"
            >
              {{ data.item.project_task_spent_minutes ?(data.item.project_task_spent_minutes / 60).toFixed(2):null }}
            </span>
          </template>
          <template #cell(duedate)="data">
            <span
              class="esttime"
              style="color: red"
            >{{ data.item.due_date }}
            </span>

          </template>

          <template #cell(priority)="data">
            <b-icon
              v-if="data.item.priority === 'high'"
              v-b-tooltip.hover.top="'High'"
              icon="Flag-fill"
              class="flag-fill-priority ml-0"
            />
            <b-icon
              v-else-if="data.item.priority === 'med'"
              v-b-tooltip.hover.top="'Medium'"
              icon="Flag-fill"
              class="flag-fill-priority-medium ml-0"
            />
            <b-icon
              v-else
              v-b-tooltip.hover.top="'Low'"
              icon="Flag-fill"
              class="flag-fill-priority-low ml-0"
            />
          </template>
          <template #cell(action)="data">
            <div class="boardDropdown">
              <b-dropdown
                toggle-class="text-decoration-none"
                no-caret
                dropleft
                text="Drop-Left"
              >
                <template v-slot:button-content>
                  <button class="edit-del-button">
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="21"
                      class="align-left edit-data-delete"
                    />
                  </button>
                </template>
                <b-dropdown-item class="edit-delete-item">
                  <feather-icon
                    icon="LogOutIcon"
                    size="16"
                    class="mr-50 edit-icons"
                  />

                  <span
                    class="edit-data"
                    @click="sendToBoard(data.item.id)"
                  >Send to Board
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <img
          v-if="archiveTasks.length == 0 && !loading"
          src="@/assets/images/nodatafound/nodata.svg"
          alt="no-data"
          class="no-data-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import TableLoader from '@/components/loaders/table-loading.vue'

export default {
  components: {
    BTable,
    TableLoader,
  },
  props: ['type'],
  data() {
    return {
      projectname: null,
      fields: [
        // A virtual column that doesn't exist in items
        { key: 'title', label: 'Title', thStyle: { minWidth: '250px' } },
        // A column that needs custom formatting
        {
          key: 'ticketno',
          label: 'Ticket No.',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        // A regular column
        { key: 'status', label: 'Status', thStyle: { minWidth: '150px', textAlign: 'start' } },
        // A regular column
        {
          key: 'timeest',
          label: 'Time Estimated',
          thStyle: { minWidth: '200px', textAlign: 'start' },
        },
        // A virtual column made up from two fields
        {
          key: 'timespent',
          label: 'Time Spent',
          thStyle: { minWidth: '150px', textAlign: 'start' },
        },
        { key: 'duedate', label: 'Due Date', thStyle: { minWidth: '150px' } },
        { key: 'priority', label: 'Priority', thStyle: { minWidth: '150px' } },
        { key: 'action', label: 'Action' },
      ],
      archiveTasks: [],
      boardType: null,
      loading: false,
    }
  },
  mounted() {
    if (this.$route.params.type || this.$route.params.id) {
      this.projectname = this.$route.params.projectname
      this.boardType = this.$route.params.type
      this.archiveTaskList(this.$route.params.type, this.$route.params.id)
    }
  },

  methods: {
    async archiveTaskList(type, id) {
      this.loading = true
      if (type == 'project') {
        const input = {
          is_archived: true,
          project_id: id,
        }
        const response = await this.getHTTPPostResponse(
          'project/task',
          input,
          false,
        )

        if (response && response.data) {
          this.archiveTasks = response.data.project_tasks
        }
      } else if (type == 'general') {
        const input = {
          is_archived: true,
        }
        const response = await this.getHTTPPostResponse(
          'project/task/board-tasks',
          input,
          false,
        )

        if (response && response.data) {
          this.archiveTasks = response.data.project_tasks
        }
      }
      this.loading = false
    },

    async sendToBoard(id) {
      const input = {
        project_task_id: id,
        type: this.$route.params.type,
      }

      const response = await this.getHTTPPostResponse(
        'project/task/undo-task',
        input,
        true,
      )

      if (response && response.data) {
        this.archiveTaskList(this.$route.params.type, this.$route.params.id)
      }
    },

    Back() {
      if (this.$route.params.type == 'project') {
        this.$router.back()
      } else {
        this.$router.back()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/component-css/approvals.css";
@import "../../../assets/scss/component-css/report.css";
@import "../../../assets/scss/component-css/board.css";
</style>
